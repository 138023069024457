html,body {
    height:100%;
    width: 100%;
}

.masthead {
    height: 100vh;
    min-height: 100vh;
    border-radius: 6px;
}

.fill{
    box-sizing: border-box;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.homapage-section{
    border-radius: 6px;
    border: 1px solid #B7B7B7;
    -webkit-box-shadow: 0px 0px 1px 2px #B7B7B7; 
    -moz-box-shadow:    0px 0px 1px 2px #B7B7B7;  
    box-shadow:         0px 0px 1px 2px #B7B7B7; 
}
.main{
    box-sizing: border-box;
    position: absolute;
    display: block;
    display: table;
    top: 100%;
    margin: 0px;
    height: 100vh;
    width: 100%;
    background-color: #CCC;
    border-radius: 6px;
}



.middle{
    display: table-cell;
    vertical-align: bottom;
}



.menu {
    display: flex;
    padding: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.menu{
    margin-left: auto;
    margin-right: auto;
}
.menu{
    -webkit-box-shadow: 3px 3px 5px 6px #ccc; 
    -moz-box-shadow:    3px 3px 5px 6px #ccc;  
    box-shadow:         3px 3px 5px 6px #ccc;  
}
@media (min-width: 768px) {
    .menu {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .menu {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .menu {
        width: 1170px;
    }
}



.app{
    display: inline-block;
    height: 80px;
    width: 80px;   
    max-height: 80px;
    max-width: 80px;
    border-radius: 14px;
    text-align: center;
    margin: 10px;
    vertical-align: top;
    margin-top: 20px;
    font-weight: bolder;
    font-family: sans-serif;
}

.app:hover{
    transform: translateY(-5px);
    transition: transform 0.2s ease-in-out;
}
.app{
    transform: translateY(0px);
    transition: transform 0.1s ease-in-out;
}

.app,.app-sm{
    border: 1px solid #D4D4D4;
    -webkit-box-shadow: 0px 0px 1px 2px #D4D4D4; 
    -moz-box-shadow:    0px 0px 1px 2px #D4D4D4;  
    box-shadow:         0px 0px 1px 2px #D4D4D4; 
}
.app img,.app-sm img{
    display: inline-block;
    width: 90%;
    height: auto;
    margin-top: 5%;
    margin-bottom: 5%;
}
.app span{
    display: block;
    margin-top: 2px;
}

.app-hover:hover{
    cursor: pointer;
    box-shadow: 5px 5px #888888;
}

.app-active:active{
    box-shadow: 5px 5px #9BA48D;
}


@media only screen and (min-width : 320px) {
    .app {
        height: 82px;
        width: 82px;
        max-height: 82px;
        max-width: 82px;
        border-radius: 14px;
    }
}

@media only screen and (min-width : 380px) {
    .app {
        height: 95px;
        width: 95px;
        max-height: 95px;
        max-width: 95px;
        border-radius: 14px;
    }
}


@media only screen and (min-width : 500px) {
    .app {
        height: 120px;
        width: 120px;
        max-height: 120px;
        max-width: 120px;
        border-radius: 20px;
    }
}


@media only screen and (min-width : 770px) {
    .app{
        height: 150px;
        width: 150px;   
        max-height: 150px;
        max-width: 150px;
        border-radius: 24px;
        margin: 20px; 
    }
}


@media only screen and (min-width : 992px) {
    .app{
        height: 200px;
        width: 200px; 
        max-height: 200px;
        max-width: 200px;
        border-radius: 24px;   
    }
}


@media only screen and (min-width : 1200px) {
    .app{
        height: 200px;
        width: 200px;   
        max-height: 200px;
        max-width: 200px; 
    }
}


.menu-container {
    min-width: 310px;
}
@media only screen and (min-width : 320px){
    .menu-container {
        width: 320px;
    }
}

@media only screen and (min-width : 380px) {
    .menu-container {
        width: 350px;
    }
}

@media only screen and (min-width : 462px) {
    .menu-container {
        width: 350px;
    }
}
@media only screen and (min-width : 500px) {
    .menu-container {
        width: 420px;
    }
}

@media only screen and (min-width : 562px) {
    .menu-container {
        width: 420px;
    }
}

@media only screen and (min-width : 702px) {
    .menu-container {
        width: 560px;
    }
}

@media only screen and (min-width : 770px) {
    .menu-container {
        width: 570px;
    }
}
@media only screen and (min-width : 992px) {
    .menu-container {
       width: 720px;
    }
}

@media only screen and (min-width : 1200px) {
    .menu-container {
        width: 960px;
    }
}


.slider{
    background-color: blanchedalmond;
    width: 100%;
    padding: 0;
    margin: 0;
}

.app-sm{
    margin: 10px;
    text-align: center;
    height: 70px;
    width: 70px;
    border-radius: 14px;
}

.loading{
    background-color: #CCCCCC;
}

.quote {
    color: #FFF0C8;
    font-weight: 700;
    font-size: 30px;
    border-left: none;
    font-family: serif;
    letter-spacing: 0.09em;
    transition: font-size 0.6s ease-in-out;
}

.quote[data-author]:hover:after,
.quote.author[data-author]:after {
    content: attr(data-author);
    position: relative;
    font-weight: 100;
    font-size: 22px;
    display: block;
    font-family: monospace;
    letter-spacing: 0px;
}

.quote:hover,
.quote.author{
    color: #FFF2D2;
    font-size: 35px;
    cursor:none;
    transition: font-size 0.6s ease-in-out;
}

.quote:hover:after,
.quote.author:after{
    font-size: 17px;
    transition: font-size 1.2s ease-in-out;
}

.quote.loading{
    width:70%;
    min-width:50px;
    max-width: 500px;
    height:20px;
    margin-left:auto;
    margin-right:auto;
    border-radius:7px;
    margin-top:10px
}




.login{
    position: relative;
    display: block;
    width: 100%;
    height: 44px;
    margin: 0px;
    margin-top: 15px;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    color : white;
    font-family: monospace;
    font-size: 16px;
    font-weight: 500;
}
.login{
    -webkit-box-shadow: 1px 1px 4px 0px #ccc; 
    -moz-box-shadow:    1px 1px 4px 0px #ccc;  
    box-shadow:         1px 1px 4px 0px #ccc;  
}
.login{
    transform: translateY(0px);
    transition: transform 0.2s ease-in-out;
}

.login:hover{
    transform: translateY(-3px);
    transition: transform 0.1s ease-in-out;
}
.login:active{
    -webkit-box-shadow: 1px 1px 1px 2px #9BA48D; 
    -moz-box-shadow:    1px 1px 1px 2px #9BA48D;  
    box-shadow:         1px 1px 1px 2px #9BA48D;  
}

.login span{
    padding: 10px;
    display: inline-block;
}
.login svg{
    width: 20px;
    height: 20px;
    margin-top: 2px;
}

.login-close{
    width: 15px;
    background-color: blue;
}
.login-close {
    position: relative;
    left: 0px;
    cursor: pointer;
    transition: opacity .2s;
    opacity: 0.4;
}

.login-close:hover {
    opacity: 1;
}
.login-close::before {
    transform: rotate(45deg);
}
.login-close::after {
    transform: rotate(-45deg);
}

.login-close::before,
.login-close::after {
    content: "";
    width: 5px;
    height: 25px;
    background-color: black;
    position: absolute;
    border-radius: 4px;
}


.hidden{
    display:none;
}

.noscroll{
    overflow: hidden;
}

.black-background-static {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-x: hidden;
    display: table;
    z-index: 20;
}


.item-header {
    display: block;
    position: relative;
    height: 40px;
    border: 1px solid #D9DADB;
    border-radius: 5px;
    padding: 5px;
    font-size: 20px;
    cursor: pointer;
}
.item-header:hover {
    background-color: #F8F9FA;
}

.item-header::after{
    content:"\02228";
    display: inline-block;
    position: absolute;
    right: 15px;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}


.blank{
    height: 30px;
    display: hidden;
}
